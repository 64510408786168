@import "../../variables";

.Flier {
    width: 100%;
    height: 280px;
    background-color: $red;
}

.Legend {
    display: block;
    padding: 8px 16px;
    font-family: 'Open Sans', sans-serif;
}

.legend-title {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #333;
    -webkit-margin-before: 0.83em;
    -webkit-margin-after: 0.83em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
}

.legend-item-wrapper {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    min-height: 22px;
    font-weight: 400;
}

.legend-item-text {
    position: relative;
    bottom: 3px;
}

.sub-section {
    padding: 8px 16px 0 16px;
}

.games-icon {
    font-size: 19px;
    color: #917658;
}

.map-pin {
    height: 15px;
}

.legend-box { 
    padding: 4px;
    margin: 0 10px 0 0;
}

.list-item-wrapper {
    padding-left: 16px !important;
    padding-right: 1px !important;
}

.list-item-text {
    font-size: 14px !important;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 600 !important;
    color: $blue;
}

.list-link {
    text-transform: none;
    text-decoration: none;
}

